@mixin link {
  text-decoration: none;
  cursor: pointer;

  &.underline {
    text-decoration: underline;
  }
}

.link {
  @include link;
}
