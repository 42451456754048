$colors: (
  --sun-crete-100: #FFF6EB,
  --sun-crete-600: #FB8C00,
  --sun-crete-700: #C97000,

  --jazlyn-100: #FFFFFF,
  --jazlyn-300: #F6F6F6,
  --jazlyn-400: #F1F1F1,
  --jazlyn-500: #E2E0E3,
  --jazlyn-600: #C4C1C8,
  --jazlyn-700: #898390,

  --travalign-deep-blue-shadow: #08214D66,
  --travalign-deep-blue: #0A1D3D,
  --travalign-main: #121F7B,
  
  --red-100: #FDEEEE,
  --red-200: #FAD5D5,
  --red-600: #E52E2E,
  --red-700: #B72525,

  --green-100: #EDF8ED,
  --green-200: #D2EDD2,
  --green-400: #1FA61F,
  --green-700: #1A861A,
  --green-800: #136413,

  --blue-100: #EEF4FD,
  --blue-200: #D5E5FB,
  --blue-500: #2662BB,

  --lightblue-500: #5995EE,
  --lightblue-600: #2F7BEA,
  --lightblue-700: #00AEFA,

  --skeleton: #8995a01a,
  --background: #FBFBFB,

  --button-bg-primary-hover: #0B0A0D,
  --button-text-pressed: #C4C1C8,

  --box-shadow: #0A1D391F,
);

:root {
  @each $class, $color in $colors {
    #{$class}: $color;
  }
}

@each $class, $color in $colors {
  .text-#{str-replace($class, '--', '')} {
    color: $color;
  }
}
