$spacer: 16px;

$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer * 0.75,
  4: $spacer,
  5: $spacer * 1.25,
  6: $spacer * 1.5,
  7: $spacer * 1.75,
  8: $spacer * 2,
  9: $spacer * 2.25,
  10: $spacer * 2.5,
);

@use 'sass:map';
$utilities: map-merge(
  (),
  (
    'margin': (
      property: margin,
      class: m,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    'margin-x': (
      property: margin-right margin-left,
      class: mx,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    'margin-y': (
      property: margin-top margin-bottom,
      class: my,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    'margin-top': (
      property: margin-top,
      class: mt,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    'margin-right': (
      property: margin-right,
      class: mr,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    'margin-bottom': (
      property: margin-bottom,
      class: mb,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    'margin-left': (
      property: margin-left,
      class: ml,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    'padding':
      (
        property: padding,
        class: p,
        values: $spacers,
      ),
    'padding-x': (
      property: padding-right padding-left,
      class: px,
      values: $spacers,
    ),
    'padding-y': (
      property: padding-top padding-bottom,
      class: py,
      values: $spacers,
    ),
    'padding-top': (
      property: padding-top,
      class: pt,
      values: $spacers,
    ),
    'padding-right': (
      property: padding-right,
      class: pr,
      values: $spacers,
    ),
    'padding-bottom': (
      property: padding-bottom,
      class: pb,
      values: $spacers,
    ),
    'padding-left': (
      property: padding-left,
      class: pl,
      values: $spacers,
    ),
  )
);

@each $utility, $args in $utilities {
  $class: map-get($args, 'class');
  $properties: map-get($args, 'property');
  @if type-of($properties) == 'string' {
    $properties: append((), $properties);
  }

  @each $index, $value in map-get($args, 'values') {
    .#{$class}-#{$index} {
      @each $property in $properties {
        #{$property}: $value;
      }
    }
  }

  @each $index, $value in map-get($args, 'values') {
    .#{$class}-md-#{$index} {
      @media #{$screen-md} {
        @each $property in $properties {
          #{$property}: $value;
        }
      }
    }
  }
}
