.button {
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;

  padding: 12px 24px;
  background-color: var(--travalign-deep-blue);
  color: var(--jazlyn-100);

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  border: none;
  border-radius: var(--border-radius);

  box-shadow: unset;

  transition: color var(--global-transition), background-color var(--global-transition);

  &:hover {
    background-color: var(--button-bg-primary-hover);
  }

  &:active,
  &.pressed {
    color: var(--button-text-pressed);
  }

  &[disabled] {
    cursor: not-allowed;
    color: var(--jazlyn-600);
    background-color: var(--jazlyn-300);
  }

  &.secondary {
    color: var(--travalign-deep-blue);
    border: 1px solid var(--jazlyn-500);
    background-color: unset;

    &:hover {
      background-color: var(--jazlyn-400);
    }
  
    &:active,
    &.pressed {
      background-color: var(--jazlyn-500);
    }
  
    &[disabled] {
      cursor: not-allowed;
      color: var(--jazlyn-600);
      background-color: unset;
      border-color: var(--jazlyn-500);
    }
  }
}
