h1,
h2,
h3 {
  margin: 0;
  display: inline-flex;
}

h1 {
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;

  &.semi-bold {
    font-weight: 600;
  }
}

h2 {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  &.semi-bold {
    font-weight: 600;
  }
}

h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  &.semi-bold {
    font-weight: 600;
  }
}

p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  &.caption {
    font-size: 12px;
    line-height: 16px;
  }

  &.caption-small {
    font-size: 10px;
    line-height: 12px;
  }

  &.semi-bold {
    font-weight: 600;
  }
}
