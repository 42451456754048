@import 'styles/breakpoints';
@import 'styles/functions';
@import 'styles/spacing';
@import 'styles/utilities';
@import 'styles/colors';
@import 'styles/variables';
@import 'styles/typography';
@import 'styles/button';
@import 'styles/link';
@import 'styles/form';

@font-face {
  font-family: 'Inter';
  src: url(assets/fonts/Inter-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Inter';
  src: url(assets/fonts/Inter-SemiBold.ttf) format('opentype');
  font-weight: 600;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  margin: 0;
}

body {
  font-family: 'Inter', 'Roboto', arial, sans-serif;
  color: var(--travalign-deep-blue);
  background-color: var(--background);
  width: 100%;
}

hr {
  margin: 0;
  border: 0;
  width: 100%;
  height: 1px;
  background-color: var(--jazlyn-500);
}
